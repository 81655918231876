.footer-div {
  height: 44px;
  color: white;
  background-color: #4a154b;
  border-top: 1px solid rgb(255 255 255 / 10%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  font-size: 15px;
}

.footer-individual-div {
  display: flex;
  flex-wrap: wrap;
  justify-content:center ;
  align-items: center;
  column-gap: 6px;
  font-size: 12px;



}
.right_box {
  display: flex;
  flex-wrap: wrap;
  justify-content:center;
  align-items: center;
}

.footer-individual-div a {
  color: #4a154b;
  cursor: pointer;
  width: 25px;

}
.footer-individual-div a:hover {
  color:blue
}


.fa-brands.fa-github:focus-visible {
  outline: unset;
  background-color: unset;
}

.fa-brands.fa-github {
  cursor: pointer;
}

.footer-individual-div img {
  cursor: pointer;
  padding-right: 0;
}

.footer-individual-div img:hover {
    background-color: black

}

.fa-github {
  padding-left: 0px;

}

#linkedin_icon {
  padding-right:0
}

#developer_ls {
  font-size:13px
}
