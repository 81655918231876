.logged-in-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 260px);
}

.profile-icon-div {
  padding: 0px 16px;
  position: relative;
  box-sizing: border-box;
}

.profile-icon-span {
  width: 26px;
  height: 26px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.profile-icon-span > img {
  width: 26px;
  background-size: 100%;
}

.nav_bar_search_login {
  flex-grow: 1;
  max-width: calc(100vw - 260px - 32px - 26px);
}
.profile-icon-span img{
  cursor:pointer;
}
