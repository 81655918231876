.all-channels-div {
    overflow-y: auto;
    height: calc(100vh - 84px - 50px);
    padding: 16px;
    font-size: 15px;
}

/* .all-channels-div::-webkit-scrollbar {
    width: 0px;
    background: transparent;
} */

.all-channles-single-div {
    padding: 16px 12px;
    border-bottom: 1px solid rgba(29, 28, 29, 0.3);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
}

.all-channels-button-div {
    text-align: center;
}

.all-channels-info-div {
    display: flex;
    flex-direction: column;
    max-width: 80%;
}

.all-channels-single-child-top {
    display: flex;
    align-items: center;
    column-gap: 6px;
    padding-bottom: 6px;
    font-size: 15px;
    overflow: hidden;
}

.all-channels-single-child-top a {
    color: inherit;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
}

.all-channels-single-child-bottom {
    display: grid;
    grid-template-columns: repeat(5, fit-content(100%)) auto;
    align-items: center;
    column-gap: 10px;
}

.all-channels-description-span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-height: 20px;
}

.join-channel-button {
    cursor: pointer;
    font-size: 15px;
    background-color: #007a5a;
    border: none;
    border-radius: 4px;
    padding: 4px 12px 5px;
    color: white;
    height: 36px;
    min-width: 80px;
    box-sizing: border-box;
}

.join-channel-button:hover {
    background-color: #148567;
    box-shadow: 0 1px 4px #0000004d;
}

.leave-channel-button {
    cursor: pointer;
    font-size: 15px;
    background-color: white;
    border: 1px solid rgba(29, 28, 29, 0.5);
    border-radius: 4px;
    padding: 4px 12px 5px;
    color: rgba(29, 28, 29, 1);
    height: 36px;
    min-width: 80px;
    box-sizing: border-box;    
}

.leave-channel-button:hover {
    background-color: rgba(248, 248, 248, 1);
    box-shadow: 0 1px 4px #0000004d;
}