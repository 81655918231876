.cm-input-container {
  width: 95%;
  margin: 1rem auto;
  border: 1px solid rgba(221, 221, 221, 1);
  display: flex;
  border-radius: 0.5rem;
  background-color: white;
  height: fit-content;
}

.cm-input-block {
  width: 100%;
  height: fit-content;
}

.cm-form {
  height: fit-content;
  max-height: 300px;
}

.cm-input-box {
  width: 100%;
  display: flex;
  justify-content: end;
}

.cm-input-top {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  height: 25px;
  background-color: rgb(211, 211, 211, 0.1);
}

.cm-input-top-box {
  padding-right: 1.5rem;
  color: rgb(211, 211, 211, 1);
}

.cm-input-box {
  padding: 0.5rem 1rem;
  height: 80px;
}

.cm-input {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
  font-family: "Noto Sans";
  font-size: 0.8rem;
  line-height: 1rem;
}

.cm-input-bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: fit-content;
  border-top: 1px solid rgba(221, 221, 221, 1);
}

.cm-submit-box {
  width: fit-content;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cm-submit-button,
.cm-cancel-button {
  padding: 0.25rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  font-size: 0.75rem;
  text-align: center;
}

.cm-cancel-button {
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: white;
  margin-right: 0.5rem;
}

.cm-submit-button-highlight-false {
  color: rgb(221, 221, 221);
  background-color: white;
  border: none;
}
.cm-submit-button-highlight-true {
  color: #ffffff;
  background-color: #2bac76;
  border: none;
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.editor-class {
  padding: 0.5rem 0.75rem;
  display: block;
  max-height: 200px !important;
}

.toolbar-class.rdw-editor-toolbar {
  margin-bottom: 0px;
  background-color: rgba(221, 221, 221, 0.25);
}

.rdw-option-wrapper {
  background-color: unset !important;
  border: unset !important;
  border-radius: 0.25rem !important;
  padding: 0.5rem !important;
  margin: unset !important;
  min-height: 25px !important;
  max-height: 25px !important;
  height: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  width: 25px !important;
}

.rdw-option-wrapper:hover {
  background-color: rgba(221, 221, 221, 1) !important;
  box-shadow: unset !important;
}