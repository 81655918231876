.add-member-div {
    width: 600px;
    max-width: 600px;
    height: min(35vh, 218px);
    background-color: white;
    border-radius: 8px;
    padding-top: 20px;
}

.edit-channel-div {
    padding-top: 20px;
    width: 520px;
    max-width: 520px;
    height: min(85vh, 316px);
    background-color: white;
    border-radius: 8px;
}