* {
  box-sizing: border-box;
}

body {
  font-family: "Noto Sans";
  margin: 0;
  height: 100vh;
  position: relative;
}

#root {
  height: 100vh;
}

.dm-header-icon-detail,
.cm-avatar {
  border-radius: 0.25rem;
}