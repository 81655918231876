.channel_msg_container{
    display:flex;

}

.user_icon{
    margin-left: 0px;

}

img.user_icon {

    border: 1px solid #c4c0c0;
    border-radius: 5px;
    margin:6px;
    width:35px;
    height:35px;
    /* margin-top:23px; */

}

.channel_msg_container {
    border:1px solid rgb(233, 223, 223);
    border-radius:10px;
    width:93%;
    font-size: 13px;
    margin:10px;
    background-color: rgb(251, 251, 251);
    display:flex;
    flex-direction:column;
}

.channel_msg_ls {
    display:flex;
    flex-direction: row;
}

.small_box {
    display:flex;
    flex-direction:column;
}

.channel_name_ls {
    font-size: 11px;
    text-align: left;

}
#channel_name {
    font-weight: bold;
    color: rgb(38, 38, 185)
}

#user_name {
    margin-top:4px;
    font-weight: bolder;
    font-size: 14px;
}

#search_content {
    margin-top: 3px;
}

.result_channel {
    font-size:15px;
    margin: 10px;
    height: 50px;
}

.view_link {

    text-decoration: none;
    color: rgb(42, 40, 40);
    margin-right:30px
}

.view_link:hover{
    font-weight: bolder;
    color:rgb(38, 38, 185);

}

.first_line {
    display: flex;
    justify-content: space-between;
}

.search-container {
    height:30vh;
    overflow-y: scroll;
}
