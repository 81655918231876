.user-form {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  height: calc(100% - 301px);
}

.user-form-inputs-div,
.user-form-button-div {
  width: 400px;
  height: 64px;
}

.login-error-div {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  margin: 15px 0px;
}

.user-form-inputs-div > input {
  width: 100%;
  padding: 11px 40px 13px 12px;
  border: 1px solid rgba(29, 28, 29, 0.3);
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 18px;
  background: white;
  box-sizing: border-box;
}

.user-form-button-div > button {
  cursor: pointer;
  width: 100%;
  padding: 12px;
  border: 1px solid #611f69;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 18px;
  background: #611f69;
  box-sizing: border-box;
  color: white;
  cursor: pointer;
}

.logout-button {
cursor: pointer;
}

.form-error-container {
  width: 400px;
  padding-bottom: 1rem;
  color: red;
}

.form-error-block {
  padding: 0.2rem 0;

}
