.profile-menu-div {
  color: rgb(29, 28, 29);
  background-color: rgb(248, 248, 248);
  position: absolute;
  right: 16px;
  top: 32px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 4px 12px 0 #0000001f;
  max-width: 360px;
  min-width: 200px;
  width: 300px;
  z-index: 4;
  padding: 1rem 0;
}

.profile-block {
  padding: 0.4rem 1.5rem;
}

.logout-button {
  text-align: left;
  width: 100%;
  padding: 0;
  border: none;
  color: rgba(0, 0, 0, 0.7);
  background: transparent;
  font-size: 0.9rem;
}

/* .profile-menu-div-sub {
  width: 100%;
  height: 100%;
  padding: 12px 0px;
  display: flex;
  flex-direction: column;
}

.profile-basic-div {
  display: flex;
  flex-wrap: wrap;
  padding: 24px 8px 20px 24px;
  align-items: center;
}

.profile-icon-span-menu {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  overflow: hidden;
  display: inline-block;
}

.profile-icon-span-menu > img {
  width: 36px;
  background-size: 100%;
}

.profile-basic-sub-div {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}

.profile-status-div {
  padding: 0px 24px 8px;
}

.profile-status-span {
  border: 1px solid rgba(29, 28, 29, 0.13);
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.profile-buttons-div {
  border: 1px solid black;
} */
