.login-general-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 0px 15px;
    height: fit-content;
}

.login-general-div > a > img {
    height: 26px;
}