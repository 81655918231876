.profile-user-block {
  display: flex;
  column-gap: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.profile-user-block-left {
  width: 36px;
  min-width: 36px;
  max-width: 36px;
}

.profile-username-box {
  width: calc(100% - 36px);
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
}

.profile-user-block-right {
  width: calc(100% - 36px);
}

.profile-avatar {
  width: 36px;
  height: 36px;
  border-radius: 4px;
}

.profile-online-box {
  display: flex;
  align-items: center;
  column-gap: 0.3rem;
}

.profile-online-text p {
  font-size: 0.8rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.fa-circle.fa-solid {
  color: #2bac76;
}
