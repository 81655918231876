.dm-to-user {
  margin-left: 6px;
  font-size: 18px;
  color: #1d1c1d;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dm-header-name,
.user-name,
.user-name-status {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dm-header {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.none {
  word-wrap: anywhere;
}