.channel-details-div {
    padding-top: 20px;
    width: min(580px, 100vw);
    max-width: 580px;
    height: min(85vh, 560px);
    background-color: white;
    border-radius: 8px;
}

.channel-details-header {
    margin: 0px 28px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    width: calc(100% - 28px);
}

.modal-exit-button {
    cursor: pointer;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    border: none;
    background-color: white
}

.modal-exit-button:hover {
    background-color: rgba(29, 28, 29, 0.04);
}

.channel-name-inmodal {
    color: rgb(29, 28, 29);
    font-size: 22px;
    font-weight: 700;
    align-items: center;
    column-gap: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: calc(100% - 36px - 28px);
}

.channel-details-nav {
    height: 36px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    color: #616061;
    border-bottom: 1px solid rgba(29, 28, 29, 0.13);
}

.channel-details-nav > span {
    cursor: pointer;
    margin-left: 28px;
}

.channel-details-body-about {
    height: calc(100% - 81px);
    background-color: rgba(29, 28, 29, 0.04);
    overflow-y: auto;
    width: 100%;
}

.channel-details-body-about::-webkit-scrollbar,
.channel-details-body-members::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.channel-details-body-members {
    height: calc(100% - 81px);
    overflow-y: auto;    
}

.channel-detail-about {
    margin: 16px 28px;
    background-color: white;
    border: 1px solid rgba(29, 28, 29, 0.13);
    border-radius: 12px;
    width: calc(100% - 28px - 28px);
    box-sizing: border-box;
}

.channel-detail-sections {
    padding: 16px 20px;
    border-bottom: 1px solid rgba(29, 28, 29, 0.13);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.channel-detail-sections.last {
    border-bottom: unset;
    color: rgb(224, 30, 90);
    font-weight: 500;
}

.channel-detail-content {
    color: rgba(29, 28, 29, 0.7);
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}

.channel-delete-leave {
    cursor: pointer;
}

.channel-detail-members {
    margin: 16px 28px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    overflow-y: scroll;
    height: min(55vh, 316px);
}

.channel-detail-member-div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 12px;
    color: rgb(29, 28, 29);
    font-size: 15px;
}

.channel-detail-member-add {
    height: 36px;
    width: 36px;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(18, 100, 163);
    background-color: #1d9bd11a;
}

.channel-detail-edit {
    cursor: pointer;
    font-size: 13px;
    color: rgb(18, 100, 163);
    font-weight: 600;
}

.add-people-icon-text {
    cursor: pointer;
}